var site;

site = site || {};
site.translations = site.translations || {};
site.translations.elc_general = site.translations.elc_general || {};

(function ($) {
  Drupal.behaviors.alwaysArtistryV1 = {
    $formatter: $(),
    isMobile: false,
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.$formatter = $('.js-always-artistry', context);

      self.$formatter.each(function () {
        var $thisFormatter = $(this);
        var $container = $thisFormatter.find('.js-artistry-carousel');

        if ($container.length > 0) {
          self.isMobile = Unison.fetch.now().name === 'small';
          self.initArtistryCarousel($container);
          Unison.on('change', function () {
            if (self.isMobile) {
              self.initArtistryCarousel($container);
            } else if ($container.hasClass('slick-initialized')) {
              $container.unslick();
            }
          });
        }
      });
    },
    initArtistryCarousel: function ($container) {
      var self = this;
      var slickSlide = '.js-artistry-slide';
      var $parentCarousel = $container.closest('.js-always-artistry');
      var $slickDots = $('.js-artistry-carousel-dots', $parentCarousel);
      var dotArrowsMobileData = $parentCarousel.data('dotsArrowsMobile');
      var mobileDotsArrowsArgs = self.checkDotsAndArrows(dotArrowsMobileData);
      var settings = {
        slide: slickSlide,
        dots: mobileDotsArrowsArgs.dots,
        arrows: mobileDotsArrowsArgs.arrows,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        infinite: false,
        appendDots: $slickDots,
        onSetPosition: function (slickArtistry) {
          var $mediaWrapper = $('.js-tout-block-media-wrapper', slickArtistry.$slides);
          var maxHeight = Math.max.apply(null, $mediaWrapper.map(function ()
          {
            return $(this).height();
          }).get());
          if (maxHeight > 0) {
            $mediaWrapper.height(maxHeight);
          }
        }
      };

      // Init this carousel with our settings
      if (self.isMobile) {
        $container.not('.slick-initialized').slick(settings);
      }
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    }
  };
})(jQuery, window.site || {});
